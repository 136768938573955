<template>
    <div>
        <SearchBar :axiosInstance="apiClient" />
        <SearchResults  />
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import SearchBar from './SearchBar.vue';
import SearchResults from './SearchResults.vue';
import apiClient from '../axiosConfig';

export default defineComponent({
  name: 'SearchDocument',
  data() {
    return {
      apiClient,
    };
  },
  components: {
    SearchBar,
    SearchResults,
  },
  setup() {
    // Add any Composition API logic here if needed
  }
});
</script>