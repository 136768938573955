// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import SearchDocument from '../components/SearchDocument.vue'; // Add any other routes as needed

const routes = [
  {
    path: '/',
    name: 'SearchDocument',
    component: SearchDocument,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
