// logger.js
const levels = {
    DEBUG: 1,
    INFO: 2,
    WARN: 3,
    ERROR: 4,
  };
  
  let currentLevel = levels.INFO;
  
  const setLogLevel = (level) => {
    if (levels[level] !== undefined) {
      currentLevel = levels[level];
    } else {
      console.warn(`Invalid log level: ${level}`);
    }
  };
  
  const log = (level, message) => {
    if (levels[level] >= currentLevel) {
      console.log(`[${level}] ${message}`);
    }
  };
  
  const debug = (message) => log('DEBUG', message);
  const info = (message) => log('INFO', message);
  const warn = (message) => log('WARN', message);
  const error = (message) => log('ERROR', message);
  
  export default {
    setLogLevel,
    debug,
    info,
    warn,
    error,
  };
  