<template>
    <div class="info-card" v-if="showInfoCard">
      <div class="info-card-header">
        <h5 class="info-card-title">&#9432; &nbsp; Welcome to the Traveler Reprint Application</h5>
        <button @click="closeInfoCard" class="close-info-card-button">x</button>
      </div>
      <div class="info-card-body">
        <p>
            This application allows you to search for and reprint traveler documents. To get started, enter the serial number of the traveler document you want to reprint in the search bar above.
        </p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'InfoCard',
    data() {
      return {
        showInfoCard: true,
      };
    },
    methods: {
      closeInfoCard() {
        this.showInfoCard = false;
      },
    },
  };
  </script>
  
  <style scoped>
  .info-card {
    border: 1px solid #cce5ff;
    background-color: #e9f7fd;
    color: #004085;
    border-radius: 4px;
    padding: 15px;
    margin-top: 20px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .info-card-header {
    border-bottom: 1px solid #cce5ff;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .info-card-title {
    margin: 0;
  }
  
  .info-card-body {
    font-size: 14px;
  }

  .close-info-card-button {
    background-color: transparent;
    border: none;
    color: #004085;
    font-size: 20px;
    cursor: pointer;
    float: right;
    margin-top: -30px;
  }
  </style>
  