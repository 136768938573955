import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import router from './router';
import msalInstance from '../msalConfig';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import logger from '../utils/logger';

const initApp = async () => { 
    logger.setLogLevel(process.env.VUE_APP_LOG_LEVEL);

    await msalInstance.initialize();
    await msalInstance.handleRedirectPromise();
    const account = msalInstance.getAllAccounts()[0];

    if (account) {
      msalInstance.setActiveAccount(account);
    } else {
      await msalInstance.loginRedirect({
        scopes: ["user.read"], // Add the required scopes here
      });
    }
    const app = createApp(App);

    library.add(faArrowRight)

    app.use(Toast, {
      position: 'top-right'
    });

    app.component('font-awesome-icon', FontAwesomeIcon)
    app.use(router).mount('#app');
};

initApp();