<template>
  <div class="container mt-3 text-center">
    <div>
      <img src="/no-documents-with-printer.png" alt="Search for Documents" class="img-fluid" />
    </div>
    <InfoCard />
  </div>
</template>

<script>
import InfoCard from './InfoCard.vue';

export default {
  name: 'SearchResults',
  components: {
    InfoCard
  }
};
</script>

<style scoped>
.img-fluid {
  max-width: 50%;
  height: auto;
  margin-top: 20px;
}
</style>
