// src/axiosConfig.js
import * as msal from '@azure/msal-browser';
import axios from 'axios';
import msalInstance from '../msalConfig';
import logger from '../utils/logger';

const apiClient = axios.create();

apiClient.interceptors.request.use(async (config) => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    const account = accounts[0];
    let apiScope = process.env.VUE_APP_API_CLIENT_ID;
    const tokenRequest = {
      account: account,
      scopes: [`api://${apiScope}/access_as_user`], // Replace with your API scope
    };

    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
      logger.debug('Token acquired: ', tokenResponse.accessToken);
      config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`;

      const subscriptionKeyName = process.env.VUE_APP_APIM_SUBSCRIPTION_KEY_NAME;
      const subscriptionKeyValue = process.env.VUE_APP_APIM_SUBSCRIPTION_KEY;

      // Add the subscription key to the request header
      config.headers[subscriptionKeyName] = subscriptionKeyValue;
    } catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect(tokenRequest);
      } else {
        logger.error('Error acquiring token:', error);
      }
    }
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default apiClient;
