<template>
  <div class="container mt-3">
    <div class="row justify-content-center">
      <div class="col-md-6 col-sm-8 col-12">
        <div class="input-group">
          <input
            v-model="serialNumber"
            maxlength="7"
            @input="onInput"
            @keydown="enterPressed"
            class="form-control"
            placeholder="Enter Serial Number (7 digits)"
          />
          <div class="btn-group">
            <button 
              @click="viewDocument" 
              type="button" 
              class="btn btn-primary" 
              :disabled="serialNumber.length < 7 || serialNumber === '0000000'"
              :class="{'btn-disabled': serialNumber.length < 7 || serialNumber === '0000000'}">
              <font-awesome-icon :icon="['fas', 'arrow-right']" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useToast } from 'vue-toastification';
import { ActionTypes } from '../enums/ActionTypes';
import logger from '../../utils/logger';

export default {
  props: {
    axiosInstance: {
      type: Object,
      required: true,
    },
    defaultActionType: {
      type: String,
      default: 'view',
    },
  },
  setup(props, { emit }) {
    const serialNumber = ref("");
    const actionType = ref(props.defaultActionType);
    const toast = useToast();

    const buttonText = computed(() => actionType.value);

    const onInput = (event) => {
      var numericVal = event.target.value.replace(/\D/g, '');
      serialNumber.value = numericVal;
    };

    const searchDocument = () => {
      emit("search-document", serialNumber.value);
    };

    const getUrl = (serialNumber, documentType) => {
      let documentName = serialNumber;
      if (documentType === "traveler")
        documentName = `Traveler-${serialNumber}`;
      let viewDocApiUrl = process.env.VUE_APP_VIEW_DOC_API_BASE_URL
      return `${viewDocApiUrl}/${documentType}/${documentName}`;
    };

    const enterPressed = (event) => {
      if (event.key === "Enter") {
        viewDocument();
      }
    };

    const viewDocument = async () => {
      try {
        if (serialNumber.value.length !== 7) {
          return;
        }

        let url = getUrl(serialNumber.value, "traveler");
        const response = await props.axiosInstance.get(url);
        const documentLink = response.data.labelUri;
        window.open(documentLink, "_blank");
      } catch (error) {
        if (error.response.status == 404) {
          toast.error("Could not find traveler: " + serialNumber.value);
          return;
        }
        toast.error("Failed to fetch document link");
        logger.error("Failed to fetch document link", error);
      }
    };

    return {
      serialNumber,
      buttonText,
      searchDocument,
      onInput,
      viewDocument,
      enterPressed,
      ActionTypes,
    };
  },
};
</script>

<style>
.btn-disabled {
  background-color: #6c757d;
  border-color: #6c757d;
  cursor: not-allowed;
  opacity: 0.65;
}
.btn-disabled:hover {
  background-color: #6c757d;
  border-color: #6c757d;
  cursor: not-allowed;
  opacity: 0.65;
}
</style>
