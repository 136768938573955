// src/msalConfig.js
import * as msal from "@azure/msal-browser";
import logger from './utils/logger';

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_OAUTH_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.VUE_APP_OAUTH_TENANT_ID}`,
    redirectUri: process.env.VUE_APP_OAUTH_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            logger.error(message);
            return;
          case msal.LogLevel.Info:
            logger.info(message);
            return;
          case msal.LogLevel.Verbose:
            logger.debug(message);
            return;
          case msal.LogLevel.Warning:
            logger.warn(message);
            return;
        }
      },
    },
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export default msalInstance;
