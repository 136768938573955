<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <a class="navbar-brand" href="#">D365 Traveler Reprint</a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul class="navbar-nav mb-2 mb-lg-0">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle d-flex align-items-center"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <font-awesome-icon :icon="['fas', 'user']" class="me-2" />
                <span>{{ userName }}</span>
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                <li v-if="showPreferences">
                  <router-link class="dropdown-item" to="/preferences">
                    <font-awesome-icon :icon="['fas', 'cog']" class="me-2" />
                    Preferences
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="container mt-3">
      <router-view />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser, faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import msalInstance from '../msalConfig';

library.add(faUser, faCog);

export default defineComponent({
  name: 'App',
  components: {
    FontAwesomeIcon,
  },
  setup() {
    const userName = ref('');
    const showPreferences = ref(process.env.VUE_APP_SHOW_PREFERENCES === 'true');

    onMounted(() => {
      const account = msalInstance.getAllAccounts()[0];
      if (account) {
        userName.value = account.name || account.username;
      }
    });

    return {
      userName,
      showPreferences,
    };
  }
});
</script>

<style>
body {
  background-color: #ECF3F3;
}

.navbar {
  margin-bottom: 20px;
}
</style>
